import { TextField, Box, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const TaskField = ({ value, setValue, index, removable, removeTask }) => {
  return (
    <Box sx={{ my: 1, display: "flex" }}>
      <TextField
        fullWidth
        id={`task${index}`}
        label={`Task ${index}`}
        variant="outlined"
        value={value}
        onChange={(e) => setValue(e, index)}
      />

      {removable && (
        <IconButton aria-label="delete" onClick={() => removeTask(index)}>
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default TaskField;
