import "./App.css";
import NewDay from "./pages/NewDay";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <Route path="/" exact component={Home} />
      <Route path="/new" exact component={NewDay} />
      <Route path="/login" exact component={Login} />
    </Router>
  );
};

export default App;
