import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Container, Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 5, mb: 5, textAlign:"center" }}>
        <Typography variant="h3" component="h4">
          Time Tracker
        </Typography>

        <Box sx={{ mt: 2, mb: 2, textAlign: "right" }}>
          <Link to="/new" style={{ textDecoration: "none" }}>
            <Button fullWidth variant="contained" endIcon={<AddCircleOutlineIcon />}>
              Add
            </Button>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Home;
