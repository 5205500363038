import { TextField, Container, Typography, Button } from "@material-ui/core";
import { useState } from 'react'

const Login = () => {
  const [values, setValues ] = useState({
    name: "",
    pass: ""
  })

  const handleValues = e => setValues(prev => ({...prev, [e.target.name]: e.target.value}))

  const handleSubmit = e =>{
    e.preventDefault()
  }

  return (
    <Container maxWidth="xs">
      <Typography
        variant="h3"
        component="h2"
        style={{ textAlign: "center", marginTop: "100px" }}
      >
        Login to continue
      </Typography>
      <form onSubmit={handleSubmit} style={{marginTop:"15px"}}>
        <TextField
          margin="dense"
          fullWidth
          variant="standard"
          label="username"
          name="name"
          onChange={handleValues}
          value={values.name}
        />

        <TextField
          margin="dense"
          fullWidth
          variant="standard"
          label="password"
          type="password"
          name="pass"
          onChange={handleValues}
          value={values.pass}
        />

        <Button type="submit" variant="contained" color="primary" fullWidth style={{marginTop:"15px"}}>
            Sign in
        </Button>
      </form>
    </Container>
  );
};

export default Login;
