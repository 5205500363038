import { useState } from "react";
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Container,
  CssBaseline,
  Box,
  Button,
  TextField,
  Typography,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
} from "@material-ui/core";

import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import AddIcon from "@material-ui/icons/Add";
import TaskField from "../component/TaskField";

const NewDay = () => {
  const [location, setLocation] = useState({
    primary: "home",
  });

  const [regularTask, setRegularTask] = useState({
    study: 0,
    satprep: 0,
    filmmaking: 0,
    wasted: 0,
    blogging: 0,
    schoolhours: 0,
    sleep: 0,
  });

  const [dayMood, setDayMood] = useState("normal");

  const [tasks, setTasks] = useState([
    {
      value: "",
      removable: false,
    },
  ]);

  const [submitDate, setSubmitDate] = useState(new Date());

  const handleDateChange = (newValue) => {
    setSubmitDate(newValue);
  };

  const setValueOfTask = (e, i) => {
    tasks[i].value = e.target.value;
    setTasks([...tasks]);
  };

  const [noOftasks, setNoOftasks] = useState(1);

  const addTasks = () => {
    setTasks((previousTasks) => [
      ...previousTasks,
      {
        value: "",
        removable: true,
      },
    ]);
    setNoOftasks(noOftasks + 1);
  };

  const removeTask = (i) => {
    tasks.splice(i, 1);
    setTasks([...tasks]);
  };

  const handleLocation = (e) => {
    const enteredLocation = e.target.value;
    if (enteredLocation === "home" || enteredLocation === "room")
      setLocation({ primary: enteredLocation });
    else if (enteredLocation === "other") {
      setLocation({ primary: "other", secondary: "" });
    } else {
      setLocation({ primary: "other", secondary: enteredLocation });
    }
  };

  const handleDay = (e) => {
    setDayMood(e.target.value);
  };

  const handleRegularTasks = (e) => {
    const { value, name } = e.target;
    setRegularTask((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 5, mb: 5, textAlign:"center" }} component="form">
        <Typography variant="h3" component="h4">
          Add new day
        </Typography>

        <CssBaseline />
        <Box sx={{ mt: 2, mb: 2, textAlign: "left" }}>
          <Typography variant="body1" component="p">
            New task:
          </Typography>

          {tasks.map((task, i) => (
            <TaskField
              key={i}
              value={task.value}
              setValue={setValueOfTask}
              index={i}
              removable={task.removable}
              removeTask={removeTask}
            />
          ))}

          <Button
            variant="contained"
            color="primary"
            endIcon={<AddIcon />}
            onClick={addTasks}
          >
            Add task
          </Button>
        </Box>

        <Box sx={{ mt: 5, mb: 3, textAlign: "left" }}>
          <Typography variant="body1" component="p">
            Time for regular tasks:
          </Typography>

          <TextField
            variant="outlined"
            value={regularTask.study}
            onChange={handleRegularTasks}
            name="study"
            label="Study Time (exclude writing notes)"
            id="study-time"
            fullWidth
            type="number"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Hours</InputAdornment>
              ),
            }}
          />

          <TextField
            variant="outlined"
            value={regularTask.satprep}
            onChange={handleRegularTasks}
            name="satprep"
            label="SAT preparation"
            id="sat-preparation-time"
            fullWidth
            type="number"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Hours</InputAdornment>
              ),
            }}
          />

          <TextField
            variant="outlined"
            value={regularTask.filmmaking}
            onChange={handleRegularTasks}
            name="filmmaking"
            label="Filmmaking"
            id="filmmaking-time"
            fullWidth
            type="number"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Hours</InputAdornment>
              ),
            }}
          />

          <TextField
            variant="outlined"
            value={regularTask.wasted}
            onChange={handleRegularTasks}
            name="wasted"
            label="Social Media (wastage)"
            id="social-media-time"
            fullWidth
            type="number"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Hours</InputAdornment>
              ),
            }}
          />

          <TextField
            variant="outlined"
            value={regularTask.blogging}
            onChange={handleRegularTasks}
            name="blogging"
            label="Blogging"
            id="blogging-time"
            fullWidth
            type="number"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Hours</InputAdornment>
              ),
            }}
          />

          <TextField
            variant="outlined"
            value={regularTask.schoolhours}
            onChange={handleRegularTasks}
            name="schoolhours"
            label="School hours"
            id="school-time"
            fullWidth
            type="number"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Hours</InputAdornment>
              ),
            }}
          />

          <TextField
            variant="outlined"
            value={regularTask.sleep}
            onChange={handleRegularTasks}
            name="sleep"
            label="Previous day sleep hours"
            id="sleeping-time"
            fullWidth
            type="number"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Hours</InputAdornment>
              ),
            }}
          />
        </Box>

        <Box sx={{ m: 2, display: "flex", alignItems: "center" }}>
          <FormControl fullWidth>
            <InputLabel id="location">Location</InputLabel>
            <Select
              labelId="location"
              id="loaction-select"
              value={location.primary}
              label="Age"
              onChange={handleLocation}
            >
              <MenuItem value={"home"}>Home</MenuItem>
              <MenuItem value={"room"}>Room</MenuItem>
              <MenuItem value={"other"}>Other</MenuItem>
            </Select>
          </FormControl>

          {location.primary === "other" && (
            <TextField
              fullWidth
              label="Enter place"
              variant="standard"
              value={location?.secondary}
              onChange={handleLocation}
            />
          )}
        </Box>

        <Box sx={{ textAlign: "left", m: 2 }}>
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Describe your day</FormLabel>
            <RadioGroup
              aria-label="day"
              defaultValue="normal"
              name="radio-buttons-group"
              onChange={handleDay}
            >
              <FormControlLabel
                value="normal"
                control={<Radio />}
                label="Normal"
              />
              <FormControlLabel
                value="productive"
                control={<Radio />}
                label="Productive"
              />
              <FormControlLabel
                value="unproductive"
                control={<Radio />}
                label="Unproductive"
              />
              <FormControlLabel
                value="burntout"
                control={<Radio />}
                label="Burnt out"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box sx={{ textAlign: "left", m: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Choose Date"
              inputFormat="MM/dd/yyyy"
              disableFuture
              value={submitDate}
              onChange={handleDateChange}
              showTodayButton
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>

        <Button fullWidth variant="contained" color="primary" size="large">
          Save
        </Button>
      </Box>
    </Container>
  );
}

export default NewDay;
